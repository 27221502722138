@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

/*******************************/
/*******************************/
/*********** Z-INDEX ***********/
/*******************************/
/*******************************/
/* Splash Screen: 99999        */
/* Hidden Splash Screen: -10   */
/* StickyBox Top: 9999         */
/* Restaurant Dropdown: 999    */
/* Menu Dropdown: 999          */
/* Edit Buttons: 998           */
/* Menu Sort List: 0           */
/* Menu Sort Item: 9998         */
/*                             */
/*                             */
/*                             */
/*                             */
/*                             */
/*                             */
/*                             */
/*******************************/
/*******************************/
/*********** Z-INDEX ***********/
/*******************************/
/*******************************/

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  font-size: 16px !important;
  -webkit-text-size-adjust: none;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
  -webkit-text-size-adjust: none;
}
::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

textarea {
  font-family: "Rubik", sans-serif;
  font-weight: 300;
}

@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
    -webkit-text-size-adjust: none;
  }
}

/*####################################
########### SPLASH SCREEN ############
####################################*/

@-webkit-keyframes fadesplash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -10;
  }
}
@-moz-keyframes fadesplash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -10;
  }
}
@-o-keyframes fadesplash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -10;
  }
}
@keyframes fadesplash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -10;
  }
}
#splashscreen {
  -webkit-animation: fadesplash ease-out 0.5s 1.2s; /* Safari 4+ */
  -moz-animation: fadesplash ease-out 0.5s 1.2s; /* Fx 5+ */
  -o-animation: fadesplash ease-out 0.5s 1.2s; /* Opera 12+ */
  animation: fadesplash ease-out 0.5s 1.2s; /* IE 10+, Fx 29+ */
  animation-fill-mode: forwards;
  height: 100%;
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  object-fit: cover;
  text-align: center;
  vertical-align: middle;
  z-index: 99999;
}

.splashlogo {
  vertical-align: middle;
  padding-top: 16vh;
  margin-bottom: 2rem;
}

/*####################################
########## RESTAURANT HOME ###########
####################################*/

#restaurantlogo {
  max-height: 80px;
  width: auto;
}

#subhead.ui.header {
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  font-size: 1.2rem;
  margin: 1rem;
  border-top: #333 !important;
}

#subhead {
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  font-size: 1.1rem;
  margin: 0.6rem;
  background-color: rgb(255, 255, 255);
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.aboutpreline {
  padding-left: 0.7rem;
  padding-right: 1.1rem;
  margin-bottom: 0.3rem;
  white-space: pre-line;
}
.aboutheaderpreline {
  padding-left: 0.7rem;
  padding-right: 1.1rem;
  margin-bottom: 0.3rem;
  margin-top: -20px !important;
  white-space: pre-line;
  font-size: large;
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 10px;
}

/* DEEP CSS */
.ui.grid > .stackable.stackable.row > .column,
.ui.stackable.grid > .column.grid > .column,
.ui.stackable.grid > .column.row > .column,
.ui.stackable.grid > .column:not(.row),
.ui.stackable.grid > .row > .column,
.ui.stackable.grid > .row > .wide.column,
.ui.stackable.grid > .wide.column {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media only screen and (min-width: 767px) {
  .aboutphotocolumn {
    margin-left: 3rem !important;
  }
  .abouttextcolumn {
    margin-right: 1.5rem !important;
  }
  .aboutphoto {
    border-radius: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .aboutheaderpreline,
  .aboutpreline {
    padding-right: 1.7rem !important;
    padding-left: 1.7rem !important;
  }
  .ui.stackable.grid:not(.vertically) > .row {
    padding: 0.4rem;
  }
}
.mainDiv {
  margin-top: 1.4rem !important;
}

.ui.grid > .row {
  padding: 0.4rem;
}
.aboutrow {
  padding-top: 1.2rem !important;
  padding-bottom: 1rem !important;
}

.nav {
  text-align: center;
  padding-top: 0.4rem;
  padding-bottom: 0.3rem;

  margin-bottom: 0.3rem;
  overflow-x: scroll;
  white-space: nowrap;
}

.menuselector {
  color: black;
  top: 0em;
  margin: 0.5rem;
  font-weight: 400;
  font-size: 1.3rem;
}

/*####################################
########## RESTAURANT MENU# ##########
####################################*/
.activegroup {
  color: rgb(0, 97, 0);
  font-weight: 600;
  border-bottom: 1px solid #333;
}

/*####################################
########## RESTAURANT HOURS ##########
####################################*/
.leftOpeningHours,
.td.centerOpeningHours,
.td.rightOpeningHours,
.leftHeaderHours,
.centerHeaderHours,
.righHeaderHours,
.ui.table thead th,
.ui.table td {
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
  text-align: center !important;
  font-family: "Rubik", sans-serif;
  font-weight: 200;
  font-size: 1.2rem !important;
  color: black !important;
}
.leftOpeningHours {
  color: black;
}
.centerOpeningHours,
.rightOpeningHours {
  white-space: pre-wrap;
}

.ui.table thead th {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  text-align: center !important;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

/*####################################
######### RESTAURANT GENERAL #########
####################################*/

.newsticky {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: white;
  width: 100%;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  margin-bottom: 0.5em;
}

#restaurantlogo {
  margin: auto;
  padding: 0.8rem 0 0.5rem 0;
}

#instagram,
#facebook,
#twitter {
  padding: 0 10% 6rem 10%;
  width: 10%;
}
#instagram,
#instagram2 {
  color: #e1306c;
}
#instagram2 {
  margin-left: 5px;
}
#facebook,
#facebook2 {
  color: #3b5998;
}
#twitter,
#twitter2 {
  color: #1da1f2;
}

.mainCont {
  top: 0;
  padding: 0 0 0 0;
}

.poweredbydiv {
  text-align: center;
  margin-bottom: 1.5em;
  max-width: 4rem;
}
.poweredbyspan {
  color: #006f32;
  font-size: x-small;
}

.contactcard {
  white-space: pre-wrap;
  text-align: center;
  font-size: small;
  line-height: 1.3;
  margin: auto;
}
#contactHeader {
  padding-bottom: 0.2rem;
  font-weight: 500;
  font-size: 1rem;
}
#contactAddress {
  padding-bottom: 1rem;
  font-size: 0.8rem;
}

/************************/

/*####################################
########### CONTROL PANEL ############
####################################*/

.mainFragment {
  text-align: center;
  margin: auto;

  overflow: hidden;
  position: fixed;
}

.signinbackground > img {
  /*width: 100vw !important;*/
  min-height: 100vh !important;
  object-fit: cover;
}
.widesigninbackground > img {
  /*width: 100vw !important;*/
  min-height: 100vh !important;
  object-fit: cover;
}

@media screen and (max-width: 650px) {
  .widesigninbackground {
    display: none !important;
  }
  .signinbackground {
    display: inline-block !important;
  }
  .mainGrid {
    color: azure;
  }
}
@media screen and (min-width: 650px) {
  .widesigninbackground {
    display: inline-block !important;
  }
  .signinbackground {
    display: none !important;
  }
  .mainGrid {
    color: black;
    text-shadow: 0.5px 0.5px 5px #ffffff;
  }
  .logintext {
    font-size: 1.2rem;
  }
}

.firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised,
.firebaseui-idp-password,
firebaseui-id-idp-button,
.firebaseui-id-submit {
  background-color: #006f32 !important;
  border-radius: 10px !important;
}

.mdl-button--primary {
  color: #006f32 !important;
  border-radius: 10px !important;
}
.firebaseui-id-email {
  border-color: #006f32 !important;
}
.firebaseui-idp-google {
  border-radius: 10px !important;
}
.firebaseui-id-page-sign-in {
  border-radius: 10px !important;
}

/*.fakegrid{
  position: absolute;
  top: 0;
  left: 10%;
}*/
.mainGrid {
  margin: auto !important;
  text-align: center;
  padding-left: 5% !important;
  padding-right: 5% !important;
  position: absolute;
  top: 0;

  /*max-width: calc(100vh*.6);*/
}

/*
.signinlogo{
  position: absolute !important;
  width: 120px;
  top:63vh;
  left: 2vh;
  z-index: 99;
}
*/

.ctrlsticky {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  background-color: white;
  width: 100%;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  margin-bottom: 0.5em;
}
#ctrlpanelbasillogo {
  max-width: 5em;
  padding: 0.4em 0.8em 0 0;
  margin-left: 1em;
}

.controlCont {
  padding: 1rem;
  margin-top: 2.2rem;
}
.restaurantdropdown,
.menudropdown,
.restaurantdropdown > div,
.menudropdown > div {
  z-index: 999 !important;
}

.ui.disabled.input {
  opacity: 0.7;
}
.ui.input {
  width: 100%;
}
.controlpanelinput.ui.input > input {
  font-family: "Rubik", sans-serif !important;
  font-weight: 300 !important;
}
.controlpanelinputnoicon {
  padding-left: 2.3rem;
}

.signoutcolumn {
  padding-left: 0 !important;
}

.contactheaders {
  margin: 1rem 0 0.5rem 0 !important;
}
@media screen and (min-width: 767px) {
  .qrrow,
  .socialrow {
    margin: 0.4rem 0 0 1rem !important;
    padding-bottom: 0 !important;
  }
  .popform {
    padding-right: 1rem !important;
  }
}
.ui.grid > .stackable.stackable.row > .column,
.ui.stackable.grid > .column.grid > .column,
.ui.stackable.grid > .column.row > .column,
.ui.stackable.grid > .column:not(.row),
.ui.stackable.grid > .row > .column,
.ui.stackable.grid > .row > .wide.column,
.ui.stackable.grid > .wide.column {
  padding: 0 !important;
}
.redirectlabel {
  display: inline;
  padding: 0 0.5em 0 0;
}

.basilurldivider {
  margin: 0 0 0.5rem 0 !important;
}

.pop {
  margin-bottom: 0.5rem;
}

.poppromodiv {
  margin-bottom: 8px;
}
.poppromolabel {
  vertical-align: super;
}
.poppromo {
  margin-top: 10px;
  margin-left: 0.8rem;
}
.emailsub {
  display: block !important;
  margin-bottom: 0.5rem;
}

.basilmenucheckbox,
.basilmenucheckbox > label,
.basilmenuredirect {
  display: inline !important;
}
.basilmenuredirect {
  padding-left: 4rem;
}
.basilmenucheckbox {
  min-width: 2rem;
}

.menuinfodiv {
  width: 100%;
  padding-top: 6px;
}

.externalmenuurl {
  margin-bottom: 8px !important;
}

.ctrlmenuheader {
  margin-bottom: 0 !important;
}

.selectaddmenu {
  margin-bottom: 0.4rem;
  font-size: 0.9rem;
  font-style: italic;
}
.basilmenupagelink {
  font-size: 0.9rem;
  font-style: italic;
}

.generalsegment {
  margin: 25px 0 0 0 !important;
  padding-left: 10px !important;
}
/*
.ctrolaccordiontitle{
  font-size: 1.2rem !important;
  font-weight: 500;
}*/

@media only screen and (max-width: 767px) {
  .stickcont {
    padding: 0 !important;
  }
}
.stickcont {
  width: 100%;
}

.divgeneraledit {
  margin: -2.1rem 0 -8px 0;
  width: 100px;
  right: 0 !important;
}
.stick,
.menustick {
  position: sticky;
  position: -webkit-sticky;
  margin-right: 8px;
  z-index: 998;
  opacity: 0.5;
}

.stick {
  top: 80px;
}
.menustick {
  top: 80px;
  margin-top: -8px;
}
.menuinfoedit,
.menuinfocancel,
.menuinfosave,
.generaledit,
.generalcancel,
.generalsave {
  position: relative;
  cursor: pointer;
  margin: 0 !important;
  right: 0;

  top: 8px;
  position: absolute;
}
.menuinfoedit,
.menuinfocancel,
.menuinfosave {
  margin-right: -8px !important;
}

.menuinfocancel,
.generalcancel {
  color: rgb(148, 0, 0) !important;
}

.menuinfosave,
.generalsave {
  color: #006f32 !important;
  /*margin-right: 0.5rem !important;*/
  top: 45px;
}

.edititemactionbutton {
  padding: 0.8rem !important;
  font-size: 0.8rem !important;
}

.pdfcolumn {
  display: block;
  margin-top: 48px;
}

.pdfupload {
  display: inline;
  vertical-align: top;
  margin-left: 11px;
}
#pdftoggle {
  padding: 0 1.7em 0 0.2rem;
  margin-right: 2px;
}

.menuhelppop {
  white-space: pre-line;
}

.highlighttoggle,
.activetoggle {
  margin-left: 0.6rem;
  margin-bottom: 0.4rem;
}
.highlighttoggle > label,
.activetoggle > label {
  padding-left: 4.2rem !important;
}

.dropzonediv {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
}

.thankyoucontrolpanel {
  font-size: 0.8rem;
  font-style: italic;
}

/*|||||||||||||||||||||||||||||||||*/
/*|||||||||||||||||||||||||||||||||*/
/*||||||||| MENU ITEM ORG |||||||||*/
/*|||||||||||||||||||||||||||||||||*/
/*|||||||||||||||||||||||||||||||||*/

.menuorgbutton {
  color: #1b1c1d !important;
  border: #1b1c1d !important;
  padding: 0 0 1rem 0 !important;
  margin: 0 0 0 1rem !important;
}

.SortableList {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;

  width: 100%; /*   WILL NEED TO ADJUST FOR MOBILE VS DESKTOP*/
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
  vertical-align: top;
}
.SortableItem {
  vertical-align: top;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 0 1rem 0 1rem; /* top right bottom left*/
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
  padding-left: 0.2rem;
}
.SortableHelper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2),
    0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;

  z-index: 9998;
}

.itembutton {
  font-family: "Rubik", sans-serif;
  font-weight: 100 !important;
  color: black !important;
  cursor: pointer;
  padding-left: 0.2rem !important;
}

.keyspan {
  font-family: "Rubik", sans-serif;
  font-weight: 100 !important;
  color: rgb(255, 255, 255);
  visibility: hidden;
  cursor: pointer;
}

#draghandle {
  padding: 0 0.5rem 0 0.5rem;
}
#additemsegment,
#additemcontent {
  padding: 0 !important;
  border: none;
}
.newItemHeaders {
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 1.1rem;
}

.newItemPhoto {
  border-radius: 10px !important;
}

.asterisk {
  vertical-align: top;
}

#additemmodal,
.edititemmodal {
  margin: 6em 0 0em 0 !important;
}

.questionicon {
  font-size: 0.8rem !important;
  vertical-align: top !important;
}

.swalpdferror,
.swalpdferror > .swal2-header > .swal2-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
}
.swalpdferror > .swal2-header > .swal2-image {
  margin: 0;
  left: 22px;
  top: 22px;
  position: absolute;
}

.swalpdferror > .swal2-content > .swal2-html-container {
  font-weight: 300;
  font-size: 1rem;
}

.swalpdferror > .swal2-footer {
  font-weight: 300;
  font-size: 0.8rem;
}
.swalpdferror > .swal2-actions {
  margin-top: 14px;
}

.swalpdferror > .swal2-actions > .swal2-confirm {
  background-color: #006f32;
}

.successswal,
.successswal > .swal2-header > .swal2-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.successswal > .swal2-content {
  font-size: 1rem;
}

.checkinswalheader > .swal2-title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  margin: 0;
}

.successswal > .swal2-header > .swal2-image,
.checkinswalheader > .swal2-image {
  margin: 0;
  left: 10px;
  top: 10px;
  position: absolute;
}

.checkinacknowledgement > .swal2-checkbox > .swal2-label {
  font-size: 0.6rem;
  padding-left: 20px;
}

.checkinacknowledgement > .swal2-validation-message {
  font-size: 0.9rem;
}

.acknowledgement {
  z-index: 999999999999 !important;
}
.checkinacknowledgement {
  padding-left: 8px !important;
}
.acknowledgecheck > input[type="checkbox"] {
  transform: scale(1.3);
  min-width: 16px;
}

.successswal > .swal2-actions > .swal2-confirm {
  background-color: #006f32;
}

.guestlogdownloadbutton,
.guestlogdownloadbutton > a {
  background-color: #006f32 !important;
  color: white !important;
}

.checkinurl {
  margin-top: 8px;
}

html.swal2-shown,
body.swal2-shown {
  overflow-y: hidden !important;
  height: auto !important;
}

@media screen and (min-width: 767px) {
  .initCheckInFunc {
    margin: 0.5rem 0 0 1rem !important;
  }
}
